@import '../../styles/styles';

.location {
  border-bottom: 1px solid $gainsboro;
  margin-top: calc(-5rem + 28px);
  padding: 5rem 0 33px;

  @include mediaQuery(max, $mobile) {
    padding: 5rem 0 24px;
  }

  h2 {
    font-family: $font-secondary;
    font-size: 22px;
    font-weight: 800;
  }
}

.content {
  font-size: 15px;
  line-height: 24px;
}
