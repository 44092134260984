@import '../../styles/styles';

.limitedTextAreaWrapper {
  position: relative;
}

.characterCounter {
  position: absolute;
  right: 0;
  top: -26px;
  margin: 0;

  &.error {
    color: $bittersweet;
    font-weight: 600;
  }
}
