@import 'styles/styles';
.navigationByCityState {
  a {
    color: #009baf;
  }
  color: #009baf;
  width: 100%;
  z-index: 6;
  position: relative;
  margin-top: 26px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-left: -10px;

  @include mediaQuery(max, $lgTablet) {
    margin-top: 15px;
  }

  @include mediaQuery(max, 650px) {
    margin-top: 15px;
  }

  @include mediaQuery(max, $mobile) {
    margin-top: 15px;
  }
}
