@import 'styles/styles';

.rating {
  display: flex;
  gap: 5px;

  span {
    font-family: $font-primary;
    font-size: 14px;
    line-height: 17px;
  }

  .reviews {
    color: $casal;
  }
}
