@import '../../styles/styles';

.amenities {
  margin-top: calc(-5rem + 28px);
  padding: 5rem 0 33px;

  @include mediaQuery(max, $mobile) {
    padding: 5rem 0 24px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &.amenitiesGrid {
      margin-left: -0.5rem;
      width: calc(100% + 1rem);
    }
  }

  h2 {
    font-family: $font-secondary;
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 1rem;
  }

  h3 {
    font-family: $font-primary;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    margin: 1.5rem 0 1rem;
    text-transform: none;
  }
}

.amenitiesGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.amenityGroup {
  border-bottom: 1px solid $gainsboro;
  padding-bottom: 20px;
  margin-bottom: 1rem;

  button {
    margin: 0;

    @include mediaQuery(max, $mobile) {
      margin-left: -10px;
      margin-top: -12px;
    }
  }
}

.amenity {
  flex: 0 0 50%;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  padding: 0 0.5rem;

  @include mediaQuery(max, $mobile) {
    flex: 0 0 100%;
  }
}

.amenitiesSeeMoreBtn {
  line-height: 20px;
}
