@import 'styles/styles';

.map {
  border-bottom: 1px solid $gainsboro;
  margin-top: calc(-5rem + 28px);
  padding: 5rem 0 33px;

  @include mediaQuery(max, $lgTablet) {
    display: none;
  }

  h2 {
    font-family: $font-secondary;
    font-size: 1.375rem;
    font-weight: 800;
    margin-bottom: 2rem;
  }
}

.mapWrapper {
  height: 400px;
  width: 100%;
  @include mediaQuery(max, $lgTablet) {
    display: none;
  }
}
