@import 'styles/styles';

.main {
  background-color: $white;
  bottom: 0;
  display: flex;
  left: 0;
  margin-top: 0;
  position: fixed;
  width: 100%;
  padding: 16px;
  justify-content: space-between;
  border: 1px solid $gainsboro;
  border-left-width: 0;
  border-right-width: 0;
}

.booknow__btn {
  &:disabled {
    cursor: text;
  }
  border: none;
}

.viewdetails__btn {
  background-color: transparent;
  border: none;
  color: $emeraldCoast;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.price__box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 0.8;

  span {
    font-size: 15px;
    line-height: 20px;
  }

  .total__price {
    span {
      &:last-child {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .no_dates {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
  }
}
