@import 'styles/styles';

.reviewModalBody {
  max-width: 775px;
  margin-left: auto;
  margin-right: auto;
}

.boldHeading {
  font-weight: bold;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 775px;
}

.reviewModalDesktopTop {
  @include mediaQuery(min, 625px) {
    justify-content: flex-end;
  }
}
