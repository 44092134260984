@import '../../styles/styles';

.reviewForm {
  max-width: 775px;
  width: 100%;

  .formBtns {
    display: flex;
    justify-content: left;

    button {
      font-size: 16px;
      margin-right: 1rem;
    }

    @include mediaQuery(max, $mobile) {
      align-items: flex-start;
      flex-wrap: wrap;

      button {
        flex: 0 0 100%;
        margin-top: 1rem;
      }
    }
    // Stop buttons from touching bottom of screen in desktop
    @include mediaQuery(min, 625px) {
      margin-bottom: 71px;
    }
  }
}

.reviewFormLabel {
  font-weight: 500;
  color: #093f45;
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin: 2rem 0;

  a {
    text-decoration: underline;
  }
}

.reviewFormTop {
  max-width: 460px;
}

.reviewFormFlex {
  display: flex;
  justify-content: space-between;

  @include mediaQuery(max, 625px) {
    flex-direction: column;
  }
}

.error {
  color: $glenRose;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin-top: 6px;
}

.reviewFormRatingHelper {
  font-weight: 500;
  color: #093f45;
  font-size: 16px !important;
  display: inline;
  margin-left: 10px;
}

.publicReviewFormBold {
  font-family: Montserrat;
  line-height: 0.3;
  font-size: 16px !important;
  font-weight: 500;
  color: $saldova;
}

.reviewFormBold {
  font-family: Montserrat;
  line-height: 0.3;
  font-size: 16px;
  font-weight: 500;
  color: $saldova;
  padding-top: 20px;

  @include mediaQuery(max, 300px) {
    line-height: 1.5;
  }
}

.privateFeedbackSub {
  font-family: Montserrat;
  font-size: 16px !important;
  letter-spacing: normal;
  color: $saldova;
  font-weight: 400;
}

.privateIconAndText {
  display: inline;
  float: left;
  margin-left: 5px;
  // margin-top: -10px;
}

.privateIcon {
  margin-bottom: -2px;
  fill: #3e5a5e;
}

.privateIconText {
  font-size: 14px !important;
  color: #3e5a5e;
  display: inline;
  text-align: right;
  font-weight: 400;
  padding-left: 8px;
  margin-right: 5px;
}

.titleInput textarea {
  min-height: 20px;
  max-height: 50px;
  overflow-y: auto;
  padding: 15px;
}
