@import '../../styles/styles';

.reviews {
  margin-top: calc(-5rem + 28px);
  padding: 5rem 0 33px;
  position: relative;

  @include mediaQuery(max, $mobile) {
    padding: 5rem 0 24px;
  }

  h2 {
    font-family: $font-secondary;
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 1.5rem;
  }
}

.writeReview {
  margin-bottom: 2rem;

  @include mediaQuery(min, $tablet) {
    margin-bottom: 0;
    position: absolute;
    right: 0;
    top: 5rem;
  }
}

.reviewOverview {
  align-items: center;
  display: flex;
  padding-bottom: 2rem;

  span {
    font-family: $font-tertiary;
    font-weight: 600;
    font-size: 1.125rem;

    &:first-of-type {
      margin-right: 0.75rem;
    }
    &:last-of-type {
      margin-left: 0.75rem;
    }
  }
}

.reviewsList {
  list-style-type: none;
  margin: 0;
  padding: 0;

  p {
    margin-top: 5px;
  }

  li {
    border-top: 1px solid $gainsboro;
    padding: 2rem 0;
    color: $saldova;

    h4 {
      font-family: $font-primary;
      font-size: 18px;
      font-weight: 600;
    }

    h4::first-letter {
      text-transform: uppercase;
    }

    header {
      align-items: baseline;
      display: flex;
      margin-top: 1.8px;
      padding: 0.4rem 0 0.75rem;

      span {
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 600;
        margin-right: 0.5rem;
      }
    }
    footer {
      padding-top: 0.5rem;

      h5 {
        font-family: $font-primary;
        font-size: 0.875rem;
        font-weight: 700;
      }

      h5::first-letter {
        text-transform: uppercase;
      }

      span {
        color: $saldova;
        font-size: 14px;
        font-family: $font-primary;
      }
    }
  }
}

.reviewBody {
  color: $saldova;
  line-height: 1.6rem;

  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.reviewResponse {
  color: $bodyText;
  border: 1px solid $divider;
  padding: 1rem;
  margin-top: 2rem;

  h5 {
    color: $blue-dark;
    font-size: 0.875rem;
    font-weight: 700;
  }

  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
