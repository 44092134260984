@import '/styles/styles';

.card {
  display: flex;
  justify-content: center;
  align-self: center;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  height: auto;
  margin-left: 8px;
  width: calc(100% - 80px);
  background-color: $white;
  flex-direction: column;
  text-align: center;
  padding: 28px 5px 23px;
  color: $saldova;
  min-height: 215px;

  > * {
    align-self: center;
  }

  p {
    margin: 6px 0 0;
    font-weight: bold;
    font-family: $font-primary;
    line-height: 21px;
  }
  @include mediaQuery(max, $smTablet) {
    display: block;
    min-height: auto;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: calc(100% - 250px);
    margin-left: 0;
    margin-right: calc(-100% + 4px);
  }
}
