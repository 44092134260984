@import 'styles/styles';

@mixin smallText {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;

  @include mediaQuery(max, $mobile) {
    font-size: 10px;
  }
}

.card {
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;

  .info {
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include mediaQuery(max, $mobile) {
      padding: 8px;
    }
  }

  h5,
  .price {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }
  h5 {
    margin-bottom: 10px;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 16px;
    @include mediaQuery(max, $mobile) {
      font-size: 14px;
    }
  }
  a {
    color: inherit;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.location {
  @include smallText();
  padding: 0;
  margin-bottom: 5px;
  margin-top: 0;
}

.basic__info {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 15px;

  span {
    @include smallText();
    letter-spacing: 0px;
    padding-right: 10px;
    padding-left: 0;
    flex: 0 auto;
  }
}
.listing__image {
  height: 106px;
  position: relative;

  .unavailable__img {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $grey;
    height: 100%;
  }
}

.middle__section {
  display: flex;
  flex-wrap: wrap;
  svg {
    width: 1rem;
    margin-right: 6px;
    path {
      fill: $saldova;
    }
  }
}

.bottom__section {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  gap: 10px;
}

.rating {
  align-items: flex-end;
  gap: 3px;

  *[class='star-ratings'] {
    height: 14px;
    display: flex !important;
    align-items: center;

    *[class='star-container'] {
      line-height: 11px;
    }
  }

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 0;
  }
}

.amenities {
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;
  justify-content: space-between;
  gap: 10px 0;

  *[class='cross'] {
    svg {
      path {
        fill: #ca2608;
      }
    }
  }
  svg {
    width: 12px;
    flex-shrink: 0;
    @include mediaQuery(max, $mobile) {
      width: 10px;
    }
  }

  > div {
    @include smallText();
    display: flex;
    letter-spacing: 0px;
    align-items: center;
    max-width: 50%;
    line-height: 12px;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:nth-child(odd) {
      flex: 0 0 calc(50% - 16px);
      overflow: hidden;
    }
    &:nth-child(even) {
      flex: 0 0 50%;
    }
  }
}

.type__label {
  background-color: $dark-cerulean;
  color: $white;
  border-radius: 3px 3px 0 0;
  position: absolute;
  bottom: 0;
  left: 16px;
  padding: 4px 7px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
  @include mediaQuery(max, $mobile) {
    left: 8px;
  }
}

.amenities__hidden {
  min-height: 177px;
  .middle__section {
    margin-top: 5px;
  }
  .basic__info {
    margin-bottom: 10px;
  }
  .bottom__section {
    gap: 15px;
  }
}
