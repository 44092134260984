@import '../../../../../styles/styles';

.messageContent {
  display: flex;
  vertical-align: middle;
  align-items: center;
  font-weight: 400;
  gap: 16px;

  svg {
    flex-shrink: 0;
  }

  @include mediaQuery(max, $smTablet) {
    width: 100%;
    font-size: 12px;
    line-height: 14.63px;
    padding: 12px 6px;
    gap: 12px;
    border: 1px solid $divider;
    margin-top: 20px;
    align-items: center;
  }
}
