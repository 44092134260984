@import '../../styles/styles';

.description {
  border-bottom: 1px solid $gainsboro;
  padding: 28px 0 33px;

  @include mediaQuery(max, $mobile) {
    padding: 24px 0px 14px;
  }

  h2 {
    font-family: $font-secondary;
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: none;
  }
}

.descriptionTitle {
  text-transform: capitalize;
}

.descriptionContent {
  font-size: 16px;
  line-height: 24px;
  min-height: 24px;
}

.rentalAgreement {
  display: block;
  font-weight: 600;
  margin-bottom: 32px;
}

.section {
  margin-bottom: 32px;
  padding: 0;
}

.instructionCheckTime {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 500;
  color: $saldova;

  > div {
    padding: 3px;
  }
}
