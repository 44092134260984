@import '../../styles/styles';

.questionForm {
  margin: 20px 0;
  width: 100%;
  z-index: 0;

  @include mediaQuery(max, $lgTablet) {
    padding: 0 32px;
    padding-bottom: 20px;
  }
}

.formBtns {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 12px;

  button {
    font-size: 16px;
    margin-right: 1rem;
    @include verticalAlign;
    justify-content: center;
    height: 50px;
  }

  @include mediaQuery(max, $mobile) {
    align-items: flex-start;
    flex-wrap: wrap;

    button {
      flex: 0 0 100%;
      margin-top: 1rem;
    }

    .secondaryFormAction {
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 1rem;
    }
  }
}

.submit {
  min-width: 174px;
}

.secondaryFormAction {
  align-items: center;
  display: flex;
  justify-content: space-between;
  @include verticalAlign;

  @include mediaQuery(max, $mobile) {
    margin-top: 1rem;
  }

  span {
    color: $bodyText;
    display: block;
    font-size: 15px;
    line-height: 24px;
  }

  a {
    color: $emeraldCoast;
    font-size: 18px;
    line-height: 24px;
    padding-left: 8px;
    min-width: 148px;
  }
}

.link {
  padding: 15px 0;
  color: #1ab2c5;
  text-align: center;
  text-decoration: underline;
  display: inline-block;

  @include mediaQuery(min, $lgTablet) {
    display: none;
  }
}

.agreement {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.agreementGroup {
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
  flex-direction: column;

  > div {
    display: flex;
    flex: 0 0 auto;
    align-items: baseline;

    > div {
      margin-bottom: 0;
    }
  }
}

.datesAndGuestsError {
  background-color: #f9e7e5;
  color: #da6960;
  padding: 10px;
  width: 320px;
  font-size: 14px;
  margin-top: 10px;
  border-radius: 2px;

  @include mediaQuery(max, $mobile) {
    width: 270px;
    margin-top: 20px;
    margin-bottom: -10px;
  }
}
