@import '../../../styles/styles';

.amenities {
  border-bottom: 1px solid $gainsboro;
  margin-top: calc(-5rem + 28px);
  padding: 5rem 0 33px;

  @include mediaQuery(max, $mobile) {
    padding: 5rem 0 24px;
  }

  h2 {
    font-family: $font-secondary;
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 1rem;
  }
}

.activityGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -0.5rem;
  width: calc(100% + 1rem);
}

.activity {
  flex: 0 0 50%;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  padding: 0 0.5rem;
}
