@import '../../styles/styles';

.shareModalNoWrapper {
  position: relative;
}

.shareModalWrapper {
  background-color: rgba(255, 255, 255, 0.95);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 7;
}

.shareModal {
  background-color: $white;
  box-shadow: $cardBoxShadow;
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 374px;

  @include mediaQuery(max, $mobile) {
    height: 100%;
    width: 100%;
  }
}

.shareModalNo {
  background-color: $white;
  box-shadow: $cardBoxShadow;
  max-height: 685px;
  position: relative;
  width: 374px;

  @include mediaQuery(max, $mobile) {
    height: 100%;
    width: 100%;
  }
}

.shareModalBody {
  height: 100%;
  overflow: auto;
  padding: 1rem 2rem;
}

.shareModalTop {
  align-items: center;
  background-color: $white;
  display: flex;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.modalClose {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 1rem;

  &.responsiveCloseIcon {
    padding: 0.9rem;

    svg {
      width: 20px;
      height: 20px;
    }

    @include mediaQuery(max, $tablet) {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.cancelBtn {
  width: 100%;
}

.shareModalMain {
  border-bottom: 1px solid $gainsboro;
  padding: 35px 0 24px;
}

.shareModalTitle {
  color: $summer-sky;
  display: block;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
}

.shareModalImg {
  margin: 16px 0 12px;
  max-height: 168px;
  width: 100%;
}

.shareModalHeadline {
  display: block;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
}

.shareModalLocation {
  background-image: url('/assets/icons/icon-pin-grey.svg');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 12px;
  color: $tax-break;
  display: block;
  font-size: 16px;
  line-height: 18px;
  margin: 5px 0 0;
  padding-left: 20px;
  padding-top: 2px;
}

.shareModalBottom {
  padding: 32px 0;

  @include mediaQuery(max, $mobile) {
    padding: 32px 0 30px;
  }
}

.shareLinks {
  align-items: center;
  color: $tax-break;
  display: flex;
  margin-bottom: 20px;
  text-decoration: none;

  &:first-child {
    svg {
      margin-left: 6px;
      margin-right: calc(1rem + 6px);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: 1rem;
    max-width: 20px;
  }
}

.twitter {
  width: 100%;
}

.success {
  color: $summer-sky;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}
