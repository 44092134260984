@import 'styles/styles';

.embla {
  width: 100%;
  &:hover {
    .embla__button__next,
    .embla__button__prev {
      visibility: visible;
      opacity: 1;
    }
    .embla__button__disabled {
      opacity: 0.3;
    }
  }

  &.main {
    position: relative;
  }
}

.embla__button {
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  fill: #1bcacd;
  height: 40px;
  justify-content: center;
  outline: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  touch-action: manipulation;
  transform: translateY(-50%);
  transition: all ease-in 0.3s;
  width: 40px;
  z-index: 0;

  &.embla__button__auto_hide {
    opacity: 0;
    visibility: hidden;
    &.embla__button__disabled {
      opacity: 0;
      &:hover {
        opacity: 0.3;
      }
    }
  }

  &.embla__button__disabled {
    cursor: default;
    opacity: 0.3;
  }
}

.embla__viewport {
  overflow: hidden;
  width: 100%;

  &.is-draggable {
    cursor: move;
    cursor: grab;
  }

  &.is-dragging {
    cursor: grabbing;
  }
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.embla__slide {
  flex: 0 0 100%;
  position: relative;
  overflow: hidden;
}

.embla__button__prev {
  right: 100%;
  left: initial;
}

.embla__button__next {
  left: 100%;
  right: initial;
}

.embla__dots {
  display: inline-block;
  background-color: transparent;
  margin-top: 10px;
  margin-left: 50%;
  transform: translateX(-50%);
  width: auto;

  .embla__container {
    cursor: default;
    min-width: 20%;
  }
}

.embla__dot {
  background-color: $white;
  border-radius: 100%;
  width: 5px;
  height: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.15rem;
  margin-left: 0.15rem;
  color: $saldova;
  border: 2px solid $saldova;
  font-size: 8px;
  padding: 2px;

  &:focus {
    border-color: $saldova;
  }

  &.is_selected {
    background-color: $saldova;
  }
}
