@import '../../styles/styles';

.success {
  margin-top: 1rem;
  max-width: 520px;
  width: 100%;

  @include mediaQuery(max, $tablet) {
    margin: 1rem 1rem 0 1rem;
    width: auto;
  }

  @include mediaQuery(max, $mobile) {
    margin: 5rem 1rem 0 1rem;
  }
}

.successBtns {
  display: flex;
  justify-content: space-between;

  button {
    width: 240px;
  }

  @include mediaQuery(max, 650px) {
    flex-direction: column;
    width: 100%;
    text-align: center;

    button {
      width: 100%;

      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
}
