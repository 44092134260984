@import '/styles/styles';

.recommended__listings {
  padding: 3rem 0;

  > p {
    margin-top: 8px;
  }
}

.listings__carrousel {
  width: 100%;
  max-width: 602px;
  @include mediaQuery(min, $smDesktop) {
    margin-left: 39px;
  }
  @include mediaQuery(max, $mobile) {
    width: calc(100% + 32px);
    margin-left: -16px;
  }

  *[class='carousel__container'] {
    position: relative;
  }
}

.carrousel__slide {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 12px;
  padding-left: 8px;
  padding-right: 8px;
  > * {
    max-width: 278px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    flex: 0 0 100%;
    border-radius: 4px;
  }

  @include mediaQuery(min, $smTablet) {
    &:last-child {
      > * {
        box-shadow: none;
        justify-content: center;
      }
    }
  }

  @include mediaQuery(max, $mobile) {
    &:first-child {
      padding-left: 16px;
    }

    > * {
      min-width: 223px;
    }
  }
}

.slide {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
