@import 'styles/styles';

.main {
  border-top: 1px solid $gainsboro;
  padding-top: 31px;
  padding-bottom: 31px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    flex-shrink: 1;
  }

  h2 {
    font-size: 22px;
    font-weight: 800;
    line-height: 26px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
  }

  button {
    @include btn-secondary;
    flex-shrink: 0;
    margin-left: 35px;
  }
}
