@import '../../styles/styles';

.sleepingArrangements {
  border-bottom: 1px solid $gainsboro;
  margin-top: calc(-5rem + 28px);
  padding: 5rem 0 33px;

  @include mediaQuery(max, $mobile) {
    padding: 5rem 0 14px;
  }

  h2 {
    font-family: $font-secondary;
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 2.5rem;
  }
}

.sleepingGrid {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;

  @include mediaQuery(max, $mobile) {
    flex-direction: column;
    align-items: center;
  }
}

.bedroom {
  align-items: center;
  display: flex;
  flex: 0 0 25%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 32px;
  padding: 0 0.5rem;
  text-align: center;

  span {
    font-size: 0.75rem;
    line-height: 1rem;

    &.name {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.25rem;
    }

    &.type {
      font-weight: 400;
      text-transform: capitalize;
    }
  }
}

.icon {
  height: 1.875rem;
  margin-bottom: 1.3125rem;
  width: auto;
  margin: 0 2.5px;
}

.additional {
  font-size: 12px;
  line-height: 16px;
}
