@import '../../../../styles/styles';
@import 'react-dates/lib/css/_datepicker.css';

.singleDateInputContainer {
  position: relative;

  & > svg {
    position: absolute;
    left: 13px;
    top: 13px;
  }

  input {
    padding-left: 3rem;
    font-weight: 500;
  }
}

.calendarDialog {
  position: absolute;
  top: 55px;
  left: 0px;
  z-index: 8;
  border-radius: 4px;
  box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 320px;
}

.calendarContainer {
  padding: 1rem;
  background-color: white;
  z-index: 11;
}
