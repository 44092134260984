@import '../../styles/styles';

.accessibility {
  margin-bottom: 32px;
}

.accessItem {
  align-items: center;
  display: flex;
  margin-bottom: 9px;

  svg {
    margin-right: 16px;
  }
}
