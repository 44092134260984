@import 'styles/styles';

.gallery {
  overflow: hidden;
  position: relative;
}

.galleryNoImages {
  align-items: center;
  background-color: $grey-transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 120px 1rem;
}

.photoIndicator {
  background-color: rgba(10, 63, 70, 1);
  background-position: 12px 12px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  padding: 14px 16px 14px 44px;
  position: absolute;
  right: 40px;
  top: 24px;
  z-index: 1;

  @include mediaQuery(max, $lgTablet) {
    bottom: 16px;
    right: 16px;
    top: initial;
    width: auto;
  }

  span {
    color: $white;
    display: block;
    font-size: 16px;
    line-height: 14px;
  }

  svg {
    position: absolute;
    left: 10px;
    bottom: 0;
    width: 36px;
  }

  .photoTotalDesktop {
    @include mediaQuery(max, $lgTablet) {
      display: none;
    }
  }

  .photoTotalTablet {
    @include mediaQuery(max, $mobile) {
      display: none;
    }
    @include mediaQuery(min, $lgTablet) {
      display: none;
    }
  }
  .photoTotalMobile {
    display: none;
    @include mediaQuery(max, $mobile) {
      display: initial;
    }
  }
}

.clickablePhoto {
  cursor: pointer;
  height: 100%;

  img {
    transform: scale(1.03);
  }
}

.photoHelper {
  @include mediaQuery(max, $lgTablet) {
    text-transform: capitalize;
  }
}

.comingSoon {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  background-color: #ebebeb;
  padding: 50px 0 50px 0;

  span {
    color: $grey-dark;
    display: block;
    font-size: 24px;
    font-style: italic;
    line-height: 28px;
    margin-top: 34px;
  }
}

.embla {
  position: relative;
}

.embla__viewport {
  overflow: hidden;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  margin-left: -0.5rem;
}

.embla__slide {
  flex: 0 0 45%;
  position: relative;
  padding-left: 0.5rem;
  @include mediaQuery(max, $lgTablet) {
    flex: 0 0 50%;
  }
  @include mediaQuery(max, $mobile) {
    flex: 0 0 100%;
  }
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  height: 30vw;
  max-height: 445px;

  @include mediaQuery(max, $lgTablet) {
    height: 33vw;
  }

  @include mediaQuery(max, $mobile) {
    height: 66.6vw;
  }
}

.embla__button {
  background-color: transparent;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 5rem;
  height: 5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.embla__button:not(:disabled) {
  cursor: pointer;
  fill: #1bcacd;
}

.embla__button:disabled {
  fill: #e9e9e9;
}

.embla__button__svg {
  width: 3.5rem;
  height: 3.5rem;
}

.embla__button--prev {
  left: 0.7rem;
}

.embla__button--next {
  right: 0.7rem;
}

.embla__dots {
  position: absolute;
  margin-top: 1rem;
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: center;
  left: 0;
  right: 0;
  top: 100%;
}

.embla__dot {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  width: 3rem;
  height: 3rem;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  border: 0;
  display: flex;
  align-items: center;
}

.embla__dot:after {
  background-color: #efefef;
  width: 100%;
  height: 0.4rem;
  content: '';
}

.embla__dot.is-selected:after {
  background-color: #1bcacd;
  opacity: 1;
}
