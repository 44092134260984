@import 'styles/styles';

.container {
  align-items: center;
  display: flex;
  max-width: 800px;

  span {
    display: block;
    font-family: $font-primary;
    font-size: 14px;
    line-height: 17px;
    margin-right: 5px;
    padding: 0 20px 0 5px;
    position: relative;

    &:after {
      bottom: 20%;
      font-size: inherit;
      content: '.';
      position: absolute;
      right: 0%;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}
