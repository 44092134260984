@import '../../../styles/styles';

.gallery {
  overflow: hidden;
  position: relative;
}

.galleryNoImages {
  align-items: center;
  background-color: $grey-transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 120px 1rem;
}

.photoIndicator {
  background-color: rgba(10, 63, 70, 1);
  background-position: 12px 12px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  padding: 14px 16px 14px 44px;
  position: absolute;
  right: 160px;
  bottom: 24px;
  z-index: 1;

  @include mediaQuery(max, $smDesktop) {
    right: 110px;
  }

  @include mediaQuery(max, $smTablet) {
    right: 60px;
  }

  span {
    color: $white;
    display: block;
    font-size: 16px;
    line-height: 14px;
  }

  svg {
    position: absolute;
    left: 10px;
    bottom: 0;
    width: 36px;
  }
}

.clickablePhoto {
  cursor: pointer;
  height: 100%;

  img {
    transform: scale(1.03);
  }
}

.comingSoon {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  background-color: #ebebeb;
  padding: 50px 0 50px 0;

  span {
    color: $grey-dark;
    display: block;
    font-size: 24px;
    font-style: italic;
    line-height: 28px;
    margin-top: 34px;
  }
}

.listingPhotosContainer {
  width: 100%;
  height: 30vw;
  max-height: 445px;

  @include mediaQuery(max, $lgTablet) {
    height: 33vw;
  }

  display: grid;
  grid-template-columns: repeat(3, 45vw 22.5vw 45vw);
  grid-gap: 0.5rem;

  :first-child {
    grid-row: 1 / span 2;
  }
  :nth-child(2) {
    grid-row: 1 / span 1;
  }
  :nth-child(3) {
    grid-row: 2 / span 1;
  }
  :last-child {
    grid-row: 1 / span 2;
  }
}
