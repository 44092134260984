@import 'styles/styles';

.questionModalWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  @include mediaQuery(min, $smDesktop) {
    flex-direction: row;
    gap: 0 32px;
  }
}

.questionModalBody {
  overflow: initial;
  padding: 0 72px;

  @include mediaQuery(max, $lgTablet) {
    margin: 71px auto;
    max-width: 655px;
    padding: 0;
  }
}

.questionBody {
  width: 100%;

  @include mediaQuery(min, $smDesktop) {
    width: 550px;
  }
}

.headline {
  display: block;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  margin-top: 5px;

  @include mediaQuery(max, $lgTablet) {
    padding: 0 32px;
  }
}

.expandable {
  border-bottom: 1px solid $divider;

  @include mediaQuery(max, $lgTablet) {
    margin: 0 auto;
    width: calc(100% - 64px);

    &:first-of-type {
      margin-top: 1rem;
      border-top: 1px solid $divider;
    }
  }
}

.expandableBtn {
  align-items: center;
  background-color: $white;
  border: none;
  display: flex;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  max-width: 222px;
  padding: 16px 0;
  width: 100%;

  svg {
    margin: 0 0 0 auto;
  }
}

.flipCaret {
  transform: rotate(180deg);
}

.rule {
  margin: 8px 0;

  &:last-child {
    margin-bottom: 16px;
  }
}

.quote {
  @include mediaQuery(max, $lgTablet) {
    display: block;
    width: 100%;
    box-shadow: none;

    :global {
      .booking__main {
        padding-bottom: 0;
      }

      .booking__cancellationPolicy {
        border-bottom: 1px solid $gainsboro;
        padding-bottom: 20px;
      }
    }
  }
}

.cancellationAlert {
  border-top: 1px solid $gainsboro;
  padding: 18px 0 0;
  margin: 18px 32px 0;
  max-width: 250px;

  @include mediaQuery(min, $smallMobile) {
    max-width: 360px;
  }

  @include mediaQuery(min, $smTablet) {
    max-width: 590px;
  }

  section {
    width: 182px;
    margin: 0;

    @include mediaQuery(min, $smallMobile) {
      width: auto;
    }
  }
}

.cancellationPolicyModal {
  section {
    max-height: 600px;
  }

  > div {
    @include mediaQuery(min, $smTablet) {
      max-width: 550px;
    }
  }
}
