@import '../../styles/styles';
@import '../../styles/helpers';

.resultDetails {
  @include mediaQuery(max, $lgTablet) {
    > footer {
      padding-bottom: 100px;
    }
  }
}

.resultDetailsContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $desktop;
  padding: 0 3rem 2rem;
  position: relative;
  z-index: 1;

  @include mediaQuery(max, 1300px) {
    margin: 0;
  }

  @include mediaQuery(max, $tablet) {
    padding: 0 16px 2rem;
  }

  .resultDetailsMain {
    margin-top: 45px;
    width: 683px;
    @include mediaQuery(max, 1250px) {
      width: 65%;
    }
    @include mediaQuery(max, 1200px) {
      width: 60%;
    }
    @include mediaQuery(max, 1150px) {
      width: 55%;
    }
    @include mediaQuery(max, 1100px) {
      width: 50%;
    }
    @include mediaQuery(max, $lgTablet) {
      width: 100%;
    }
  }

  &.viewDetailsOpen {
    z-index: 10;
  }
}

.resultDetailsSidebar {
  height: 100%;
  position: sticky;
  bottom: 0;
  align-self: flex-end;

  // At top: -2rem the sticky booking blocks 2
  // detail menu links
  // top: 60px;

  > div {
    z-index: 7;

    @include mediaQuery(min, $lgTablet) {
      z-index: 6;
      min-height: calc(100vh - 92px);
      margin: 72px 1rem 32px 0;
    }
  }
}

.hideSidebar {
  z-index: 1;
}

.link {
  width: 100%;
  padding: 15px 24px;
  color: #1ab2c5;
  text-align: center;
  text-decoration: underline;
  display: inline-block;

  @include mediaQuery(max, $lgTablet) {
    display: none;
  }
}

.difference {
  border-top: 1px solid $gainsboro;
}

.destinationSection {
  background-image: none !important;
}

.mobileSearchBar {
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $input-border;
  border-top: 1px solid $input-border;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;

  @include mediaQuery(max, $mobile) {
    top: 77px;
    width: 100%;
  }

  @include retinaQuery(max, $mobile) {
    top: 64px;
  }

  div {
    width: 100%;
  }

  input {
    min-width: 100%;
  }
}

.mobileSearchBarActive {
  @include mediaQuery(min, $tablet) {
    position: absolute;
    position: relative;
    z-index: 11;
  }
}

.mobileSearchBarHidden {
  @extend .mobileSearchBar;

  position: absolute;
  transition: $standardTransition;

  @include mediaQuery(max, $tablet) {
    top: -70px;
    z-index: 0;
  }
}
