@import '../../styles/styles';

.detailOverview {
  border-bottom: 1px solid $gainsboro;
  margin-top: calc(-5rem + 28px);
  max-width: 683px;
  padding: 2rem 0 22px;

  @include mediaQuery(max, $lgTablet) {
    padding-bottom: 0;
  }

  @include mediaQuery(min, $smDesktop) {
    padding: 3rem 0 22px;
  }

  @include mediaQuery(max, $mobile) {
    border-bottom: none;
  }

  h1 {
    font-size: 32px;
    letter-spacing: -0.3px;
    line-height: 38px;

    @include mediaQuery(max, $mobile) {
      font-size: 24px;
      letter-spacing: -0.15px;
      line-height: 26px;
    }
  }
}

.resultType {
  align-self: flex-start;
  display: inline-block;
  background-color: $dark-cerulean;
  border-radius: 3px;
  color: $white;
  font-size: 14px;
  font-weight: 600;
  max-width: 150px;
  letter-spacing: 0.83px;
  line-height: 17px;
  padding: 5px 8px 5px;
  text-transform: uppercase;

  @include mediaQuery(max, $mobile) {
    margin-right: 12px;
  }
}

.resultDetailRating {
  display: flex;

  span {
    font-family: $font-tertiary;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding-top: 1.5px;
  }
}

.starRating {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-right: 5px;

  span {
    display: block;
    font-family: $font-tertiary;
    margin-right: 4px;
    font-size: 14px;
    font-weight: bold;
  }
}

.reviews {
  color: $grey-dot-active;
  cursor: pointer;
  text-decoration: underline;
  border: none;
  border-radius: 0;
  font-size: 14px;
  background-color: white;
  padding: 0 0 1.5px 0;
}

.affirmMobileMessage {
  margin-bottom: 10px;

  @include mediaQuery(min, $lgTablet) {
    display: none;
  }
}

.detailOverviewTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  @include mediaQuery(max, $mobile) {
    align-items: center;
    justify-content: flex-start;
  }
}

.detailOverviewButtons {
  display: flex;
  justify-content: flex-end;
  width: 223px;

  button {
    z-index: auto;
  }

  @include mediaQuery(max, $mobile) {
    border-bottom: 1px solid $gainsboro;
    border-top: 1px solid $gainsboro;
    padding: 6px 0;
    width: 100%;
  }
}

.detailOverviewBtn {
  align-items: center;
  background-color: $white;
  border: 1px solid $gainsboro;
  border-radius: 4px;
  display: flex;
  font-weight: 400;
  padding: 8px 16px;
  @include mediaQuery(max, $mobile) {
    width: 100%;
    border: none;
    border-radius: 0;
    justify-content: center;
    padding: 10px 0;

    // &:first-child {
    //   border-right: 1px solid $gainsboro;
    // }
  }

  svg {
    margin-right: 11px;
  }
}

.resultLocation {
  color: $saldova;
  font-family: $font-primary;
  display: block;
  font-size: 16px;
  line-height: 20px;
  margin: 20px 0 8px;

  @include mediaQuery(max, 960px) {
    padding-top: 1px;
    margin-bottom: 10px;
  }
}

.resultDetailInfo {
  align-items: center;
  display: flex;
  max-width: 800px;

  &:last-child {
    margin-top: 3px;
  }

  span {
    color: $saldova;
    display: block;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 18px;
    margin-right: 5px;
    padding: 0 10px 0 5px;
    position: relative;

    @include mediaQuery(max, 960px) {
      font-size: 16px;
      line-height: 15px;
    }

    &:after {
      bottom: 25%;
      font-size: 18px;
      content: '.';
      position: absolute;
      right: 0;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}

.detailOverviewBottom {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include mediaQuery(max, $mobile) {
    margin-bottom: 20px;
    flex-direction: row;
  }
}

.mapButton {
  background-color: transparent;
  border: none;
  padding: 18px 0;

  @include mediaQuery(max, $mobile) {
    height: 64px;
    width: 64px;
    padding: 0;
    margin: 20px 0 10px 0;
    float: right;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}
