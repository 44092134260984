@import 'styles/styles';

.avgPrice {
  font-family: $font-primary;
  font-size: 14px;
  line-height: 17px;

  @include mediaQuery(max, 960px) {
    font-size: 12px;
    line-height: 24px;
  }
}
